'use strict';
jQuery(document).ready(function ($) {
  $('.home-slider').slick({
    infinite: true,
    autoplay: true,
    fade: true,
    arrows: false,
    dots: true,
    pauseOnHover: false,
  });

  $('.overlay-menu .menu li').removeClass('current-menu-item');

  var options = {
    valueNames: ['specialisme'],
    page: 8,
    pagination: true,
  };
  var itemList = new List('item-list', options);
  if (itemList.items.length < 9) {
    $('#item-list .pagination').hide();
  }

  $('.filter-item').click(function () {
    var $text = $(this).val();
    $('.filter-item').removeClass('active');
    $(this).addClass('active');
    itemList.filter(function (item) {
      if (item.values()['specialisme'] == $text) {
        return true;
      } else if ($text == 'all') {
        itemList.filter();
        $('.filter-item').removeClass('active');
      } else {
        return false;
      }
    });
  });
  //TANDARTSEN LIST

  var options2 = {
    valueNames: ['specialisme'],
    page: 8,
    pagination: true,
  };
  var itemList2 = new List('item-list-2', options2);
  if (itemList2.items.length < 9) {
    $('#item-list-2 .pagination').hide();
  }

  //click service item
  $('.section-three .service-block .inner').on('click', function () {
    var link = $(this).data('url');

    //window.location.href = document.location.origin + link;
    window.location.href = link;
  });
});
